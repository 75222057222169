.FullPage {
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin-left: 5vw;
  margin-right: 5vw;
  user-select: none;
}
.animate-width {
  animation: animateWidth 0.5s ease-in-out;
}

/* Define the keyframe animation for width */
@keyframes animateWidth {
  0% {
    transform: translateX(-300px);
  }

  100% {
    transform: translateX(0px);
  }
}
.non-selectable {
  user-select: none;
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For IE/Edge */
}

#CreateAccounttitle {
  font-weight: bold;
  font-size: 19px;
  width: 70%;
  text-align: center;
}
#CreateAccouninfo {
  font-weight: normal;
  font-size: 13px;
  width: 70%;
  text-align: center;
}
.SignuptextDescription {
  flex: 1;

  display: flex;
  flex-direction: column;
  margin: 10px;
  align-items: center;
  justify-content: center;
}
.NotSignupoption {
  flex: 2;

  display: flex;
  flex-direction: column;
  margin: 10px;
  align-items: center;
}
.NotSignupoption input {
  width: 200px;
  height: 20px;
  border-radius: 20px;
  margin: 5px;
  border: 1px solid rgb(0, 128, 128);
}
.Signup-to-continue button {
  background-color: rgb(0, 80, 0);
  color: aliceblue;
  height: 30px;
  border-radius: 20px;
  font-weight: bold;
  width: 200px;
}
.NotSignupoption input::placeholder {
  outline: none;
}
.Signupcontent {
  height: 86vh;
}
.Inlinetexts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Signup-to-continue {
  align-self: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  position: fixed;

  top: 20vh;
  left: auto;
  flex-wrap: wrap;
  height: 250px;

  z-index: 22;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(3, 3, 3, 0.11) 8px 3px 20px 5px,
    rgba(241, 208, 208, 0.103) 9px 9px 6px -8px;
  border-radius: 20px;
}
.user-acount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.user-acount p {
  margin-right: 5px;
}
.cart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.cart p {
  margin-right: 5px;
}
.cart #cartitemno {
  align-self: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  background-color: rgb(21, 104, 0);
  color: rgb(255, 255, 255);
  font-weight: bold;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  width: 19px;
}

.Navbar {
  background-color: white;
  z-index: 10;
  margin-top: 5px;
  align-items: center;
  display: flex;
  height: 50px;
  top: 0;
  margin-bottom: 10px;
  position: sticky;
}
.ProductCard #ProductNames {
  font-weight: bold;
  margin-top: 5px;
  width: 70%;

  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.ProductCard #Description {
  font-size: 14px;
  font-weight: 100px;
  margin-top: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.rating {
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  margin-top: 10px;
}
#ratecount {
  font-size: 13px;
  color: rgb(109, 111, 114);
  margin-left: 5px;
}
.ProductImage {
  width: 100%;
  height: 200px;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
}
.ProductTitle #Price {
  font-weight: bold;
  margin-top: 5px;
  margin-right: 5px;
}
#mobilesearch {
  width: 150px;
}
#DiscountPrice {
  font-weight: bold;
  margin-top: 5px;
  margin-right: 5px;
  text-decoration: line-through;
  color: red;
}
.ProductTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ProductImage p {
  color: white;
  padding-top: 5px;
  padding-right: 5px;
}

.ProductCard p {
  margin: 0px;
}
.Transparent-button {
  flex: 1;
  border: 1px solid black;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.Transparent-button:hover {
  background-color: rgb(0, 51, 0);
  font-weight: bold;
  color: white;
}
.AddtocartButton {
  width: 90px;
  justify-self: end;
}

.Navbar .Logo {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.Navbar .Logo p:hover {
  color: rgb(83, 84, 85);
  cursor: pointer;
}
.Navbar .Userinfo input {
  width: 260px;
  height: 30px;
  border-radius: 10px;
}
.Navbar .Userinfo input:focus {
  width: 600px;
}
.Searchboxmobile {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(30, 30, 31, 0.11) 8px 3px 20px 5px,
    rgba(241, 208, 208, 0.103) 9px 9px 6px -8px;

  width: 100vw;
  right: 0;
  position: absolute;
  height: 400px;

  animation-name: Searchboxheight;
  animation-duration: 1s;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  top: 64px;
}
.Searchbox {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(30, 30, 31, 0.11) 8px 3px 20px 5px,
    rgba(241, 208, 208, 0.103) 9px 9px 6px -8px;

  position: absolute;
  height: 300px;

  animation-name: Searchboxheight;
  animation-duration: 1s;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  top: 64px;
}
.SearchTitle {
  display: flex;
  flex-direction: row;

  height: 50px;
  align-items: center;
  margin-top: 10px;
  margin-left: 40px;
  font-weight: bold;
}
@keyframes Searchboxheight {
  from {
    height: 100px;
  }
  to {
    height: 300px;
  }
}
.SearchCard-List {
  flex: 1;
  margin-left: 20px;
}
.Searchcards {
  width: 80%;
  margin: 10px;
  height: 80px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Searchcards #SearchProductNames {
  font-size: 14px;
  font-weight: bold;
}
.Searchcards #SearchProductprice {
  font-size: 14px;
  font-weight: bold;
}
.Searchcards img {
  height: 100%;
  width: 80px;
  border-radius: 10px;
}
.Searchcards:hover {
  cursor: pointer;
  background-color: rgba(142, 142, 146, 0.055);
}
.Navbar .Userinfo input {
  outline: 1px solid rgb(0, 162, 255);
  border: none;
}
.Navbar .Userinfo {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  height: 100%;
  justify-content: space-evenly;
}

.Userinfo .user-acount:hover {
  color: rgb(65, 64, 64);
  cursor: pointer;
}
.Userinfo .cart:hover {
  color: rgb(56, 55, 54);
  cursor: pointer;
}

.BannerBox {
  display: flex;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
  background-color: rgb(247, 230, 200);
  height: 30vh;
}
.Catagorybanner {
  display: flex;

  background-color: rgb(8, 76, 88);
  height: 40vh;
  flex-direction: row;
  overflow: hidden;
}
.Catagorymobiletext {
  background-size: cover;

  width: 100%;

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 20px;
}
.catagoryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.catagoryButton button {
  width: 150px;
  height: 40px;
  border-radius: 40px;
  background-color: rgb(0, 0, 0);
  color: white;
  font-weight: bold;
}
.catagorytext {
  flex: 2;

  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
}
.catagoryimage {
  display: flex;
  width: 400px;
  animation-name: imagewidth;
  animation-duration: 2.5s;

  margin-bottom: -20px;
}
@keyframes imagewidth {
  from {
    width: 700px;
  }
  to {
    width: 400px;
  }
}
#catagorytexts {
  font-size: 49px;
  color: white;
  font-weight: bold;
  word-wrap: normal;

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0px;
}
#catagorysmalltext {
  font-size: 39px;
  word-wrap: normal;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bolder;
  color: rgb(255, 255, 255);
  margin: 0;
}
#Signupbutton {
  background-color: rgb(0, 0, 0);
  border: none;
  width: 120px;
  height: 40px;
  border-radius: 30px;
  color: white;
  font-weight: bold;
}
.catagoryimage img {
  height: 100%;
}

.BannerText {
  width: 300px;
}
.HomePage {
  min-height: 85vh;

  display: flex;
  flex-direction: column;
}
.OrderListPage {
  min-height: 85vh;

  display: flex;
  flex-direction: column;
}
.Orderlisttitle {
  height: 50px;
  background-color: rgb(241, 241, 241);
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: space-around;
}
.Orderlists {
  height: 30px;

  display: flex;
  align-items: center;
  flex-direction: row;

  justify-content: space-around;
}
.Newspage {
  min-height: 85vh;

  display: flex;
  flex-direction: column;
}
.NewsTitle {
  height: 100px;

  display: flex;
  align-items: center;
}
.NewsCard {
  display: flex;

  flex-direction: row;

  max-width: 600px;
  height: 150px;
  border: 1px solid rgb(216, 211, 211);

  margin-top: 10px;
}
.Newsimage {
  flex: 1;
}

.sidenavbar {
  position: fixed;
  width: 300px;
  box-shadow: rgba(26, 26, 27, 0.603) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  background-color: rgb(245, 245, 245);
  height: 100vh;
  top: 53px;
  right: 0px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  animation-name: sidenavbarwidth;
  animation-duration: 0.5s;
}
.Eachbutton {
  box-shadow: rgba(26, 26, 27, 0.603) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  background-color: #cccaca67;
  margin-top: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes sidenavbarwidth {
  from {
    width: 0px;
  }
  to {
    width: 300px;
  }
}
.Newsimage img {
  height: 100%;
  width: 100px;
  margin-left: 10px;
}
.Newstext {
  flex: 3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Newstext h3 {
  margin: 0px;
  padding: 0px;
}
.Newstext p {
  text-overflow: ellipsis;

  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;

  text-overflow: ellipsis;
}
.NewsList {
  display: flex;
  flex-direction: column;
}
.NewsTitle p {
  font-weight: bold;
  font-size: 23px;
}
.CatagoryHomePage {
  min-height: 85vh;

  display: flex;
  flex-direction: column;
}
.Selection {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  font-weight: normal;
  align-items: center;
}

#Select {
  font-size: 12px;
  height: 27px;
  width: 110px;
  margin: 10px;
}
.Selection p {
  margin-left: 10px;
  font-size: 18px;
  border-radius: 20px;
  width: 20px;
  background-color: rgb(187, 186, 186);
  padding: 5px;
}
.SearchIcon p {
  padding-top: 8px;
  margin-right: 5px;

  width: 25px;
  font-size: 18px;
  text-align: center;
  border-radius: 4px;
  color: rgb(20, 18, 18);
}
.SearchIcon {
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.Listtitle input {
  width: 40px;
  margin-left: 10px;
  margin-right: 5px;
}
.Listtitle button {
  margin: 5px;
  background-color: #059205;
  color: white;
  font-weight: bold;
}
.Listtitle {
  margin-top: 5px;
  display: flex;

  flex-wrap: wrap;
  align-items: center;
  font-weight: bold;
  font-size: 19px;
}
.Catagorycard p {
  background-color: #00000094;
}
.Catagorycard {
  width: 250px;
  margin-left: 10px;
  height: 300px;
  background-size: cover;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  color: rgb(255, 255, 255);
  font-weight: bold;

  margin-bottom: 17px;
}
.Catagorylist {
  display: flex;

  flex: 1;

  display: inline-flex;

  height: max-content;

  margin-top: 10px;
}
.Catagolies {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}
.ShopBody {
  display: flex;
  flex: 1;

  flex-direction: column;
}
.Product-cards {
  margin-top: 20px;
}
.Catagory-selector {
  display: flex;
  flex-direction: row;
  height: 50px;

  margin-top: 10px;
  margin-bottom: 10px;

  align-items: center;
}

.RoundButton {
  background-color: rgb(214, 212, 212);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 19px;
  margin-left: 10px;
  height: 30px;
  width: max-content;

  padding-right: 10px;
  padding-left: 10px;
}

.inline-cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-bottom: 10px;
  flex-wrap: wrap;
}
.Nameofthelist {
  height: 50px;
}
.Nameofthelist p {
  font-weight: bold;
  font-size: 19px;
}
#rates {
  z-index: -1 !important;
}

.carttab {
  min-height: 300px;
  display: flex;
  flex-direction: column;

  position: absolute;

  width: 300px;
  top: 35px;
  right: 0px;
}
@keyframes Cartboxheight {
  from {
    height: 100px;
  }
  to {
    height: 300px;
  }
}
.Topstyle {
  background-color: rgb(255, 255, 255);
  margin-left: 10px;
  margin-right: 10px;

  height: 15px;

  display: flex;
  flex-direction: row;
}
.cartItemproduct {
  display: flex;

  background-color: rgb(255, 255, 255);

  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 8px 13px 20px 5px,
    rgba(241, 208, 208, 0.103) 59px 9px 6px -8px;
  flex-direction: column;
  overflow: scroll;
  animation-name: Cartboxheight;
  animation-duration: 0.5s;
  min-height: 100px;
  max-height: 70vh;
}
@keyframes Cartboxheight {
  from {
    height: 30px;
  }
  to {
    height: 300px;
  }
}
.eachcartitems {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
}
#totalprice {
  width: 100%;
}
#totalprice p {
  border-top: 1px solid black;
  width: 100%;
  text-align: end;
}
.item {
  display: flex;
  align-items: center;

  justify-content: center;
  flex: 1;
  align-items: center;
  margin-left: 5px;
}
.OrderNow {
  background-color: rgb(7, 43, 0);
  height: 30px;
  margin: 10px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: white;
  font-weight: bold;
}
.itemscolomeun {
  align-items: center;
  display: flex;
  width: 140px;
}
.leftstylle {
  background-color: rgb(255, 255, 255);
  flex: 1;

  border-bottom-right-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 0px 0px;
  margin-bottom: 30px;
}
.Rightstyle {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 0px 0px;
  background-color: white;
  width: 76px;
  margin-left: 5px;
  border-bottom-left-radius: 20px;
}
a {
  color: inherit;
  text-decoration: none;
}
.tabcontent {
  display: flex;

  background-color: rgb(255, 255, 255);
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 8px 13px 20px 5px,
    rgba(241, 208, 208, 0.103) 59px 9px 6px -8px;
  flex-direction: column;
  overflow: scroll;
  animation-name: Cartboxheight;
  animation-duration: 0.5s;
  min-height: 100px;
  max-height: 70vh;
}
.tabcontent button {
  height: 30px;
}
.tabcontent img {
  width: 50px;
  height: 50px;
}
.ProductCard {
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 270px;
  height: 350px;
  margin: 10px;
  border: 1px solid rgba(0, 0, 0, 0.144);
  user-select: none;
  background-color: rgb(255, 255, 255);
}
.ProductPage {
  min-height: 85vh;

  display: flex;
  flex-direction: column;
}
.OrderPage {
  min-height: 85vh;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  margin-bottom: 90px;
}
.eachinfo {
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 10px;
}
.eachbankinfo {
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.eachinfo input {
  border-radius: 10px;
  border: 1px solid transparent;
  margin-left: 10px;
}
#userInfo {
  font-weight: bold;
  font-size: 23px;
}
.leftsideOrderinfo .inputboxs {
  width: 300px;
}
.leftsideOrderinfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.RightSideOrderinfo {
  flex: 1;
}
.inputboxs {
  margin-top: 20px;
}
.Path {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.Path p:hover {
  color: blue;
  text-decoration: underline;
}
.Path a {
  color: inherit;
  text-decoration: none;
}
.Largeimage {
  width: 600px;
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ProductDetails {
  width: 35vw;
  min-width: 300px;
  display: flex;
  align-items: center;

  flex-direction: column;
}
.Colorcomponent {
  height: 30px;
  width: 30px;
  border-radius: 28px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 2px;
}

.top {
  flex: 1;
  border-top-right-radius: 18px;
  border-top-left-radius: 15px;
}
.bottum {
  flex: 1;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 15px;
}
.ChooseColor {
  display: flex;
  flex-direction: row;
}
.Sizecomponent {
  background-color: rgb(209, 216, 216);
  flex: 1;
  height: 30px;
  width: 30px;
  border-radius: 3px;
  margin-left: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Choosesize {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Setquantity {
  display: flex;
}
.Quantity {
  display: flex;
  flex-direction: row;

  height: 40px;
  width: 130px;
  flex: 1;

  border-radius: 5px;
  margin-left: 5px;
}
.InlineButtons {
  display: flex;
  height: 40px;
  width: 190px;
}
.BuyAndAddtocart {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: aqua;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: rgb(0, 83, 0);
  color: white;
}
.Addtocart {
  border-radius: 10px;
  margin-left: 17px;
  border: 1px solid black;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Addtocart:hover {
  background-color: rgb(80, 82, 236);
  color: white;
}

.controllbutton {
  border: 1px solid rgb(170, 170, 170);
  background-color: #cccaca;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;

  flex: 1;
}
.controllbutton:hover {
  cursor: pointer;
}
.displayquantity {
  border: 1px solid rgb(170, 170, 170);
  margin-left: 5px;
  margin-right: 5px;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProductPrice {
  margin: 15px;
  font-weight: bold;
  font-size: 20px;
  color: rgb(92, 91, 91);
}
.ProductImg {
  width: 95%;
}
.ProductImg img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.ProductImagelist {
  height: 70px;

  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.ProductDescription {
  width: 300px;
}
.ProductImagelist .imagebutton {
  margin-left: 10px;
  border-radius: 5px;
  overflow: hidden;
}
.ProductTable {
  height: 40px;
  margin-top: 15px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 19px;
}
.SpecificationTable {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;

  margin: 20px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.SpecificationTable {
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;

  display: flex;
  flex-direction: row;
}
.ProductImagelist img {
  height: 100%;
  width: 70px;
}
.Product-PageBody {
  justify-content: center;

  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;

  flex-direction: row;
}
.ProductNames {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  background-color: rgb(250, 250, 250);
}
#DiscriptionPage {
  width: 380px;
  justify-self: center;
}
.ProductLink {
  color: inherit;
  text-decoration: none;
}
.ProductLink:active {
  -webkit-tap-highlight-color: transparent;
}

.Productname {
  display: flex;

  flex-direction: column;
  flex: 1;
  margin: 5px;
  justify-content: space-between;
}
.ProductCard:hover {
  border: 1px solid rgba(24, 1, 87, 0.137);
}
.ProductCard .Productname:hover {
  cursor: pointer;
}
.orderboxes {
  width: 100%;
  position: fixed;
  top: 40vh;
  left: 0vw;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Orderconfirmbox {
  background-color: #ffffff;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  box-shadow: rgba(26, 26, 27, 0.603) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 10px 0px -30px;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 400px;
  padding: 20px;
  color: black;
}
#orderconfno {
  -webkit-user-select: all;
  user-select: all;
}
#orderconfno:hover {
  cursor: pointer;
  color: rgb(48, 48, 48);
}
.Orderconfirmbox button {
  background-color: rgb(17, 100, 0);
  color: white;
}
.inlineSearch {
  display: flex;
  flex-direction: row;

  height: 30px;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.Ordertables p {
  word-wrap: break-word;
  width: 24vw;
}
.Ordertables table p {
  word-wrap: break-word;
}
.nodataline {
  display: flex;
  flex-direction: row;
  font-weight: bold;

  align-items: center;
  justify-content: center;
}
.SearchOrder {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SearchOrder button {
  height: max-content;

  height: 33px;
}
.SearchOrder input {
  height: 25px;
  width: 210px;
  margin-left: 10px;
}
#WeeklyPopular {
  font-weight: bold;
  font-size: 19px;
}

.pagenoButton {
  width: 30px;
  margin-left: 10px;
}
.Pageno {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.Weekly-List-Name {
  height: 50px;
  display: flex;
  flex-direction: row;

  margin-top: 10px;
}
.flatlist {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}
.Weekly-product-list {
  display: inline-flex;

  height: max-content;
}
.Services-list-name {
  height: 50px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 19px;

  margin-top: 10px;
  margin-bottom: 10px;
}
.ServiceImage {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-color: rgb(243, 204, 131);
}

.ServieceCard #ServiceTitle {
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-left: 10px;
  width: 230px;
  margin-bottom: 0px;
}
.ServieceCard #servicedesctiption {
  margin: 10px;
  width: 220px;
  font-weight: lighter;
}
.ServieceCard {
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 380px;
  height: 350px;
  margin: 10px;
  justify-content: space-between;
  background-color: rgba(250, 249, 248, 0.863);
}
.Serviece-Card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.Footer {
  margin-top: 10px;
  height: 50px;
  background-color: #4e18182f;

  display: flex;
  align-items: center;
  justify-content: center;
}
.Footer p {
  margin: 10px;
}
